.vendor:hover {
  background-color: white;
}
.vendor {
  background-color: "#57212E";
}

@font-face {
  font-family: "Town_80_Book";
  src: url("../assets/fonts/Town80Text-Book.otf");
  src: format("opentype");
}

.town80book {
  font-family: "Town_80_Book" !important;
}

/* @font-face {
  font-family: "proxima_reg";
  src: url("../assets/fonts/ProximaNova_Regular.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_bold";
  src: url("../assets/fonts/Proxima_Nova_Bold.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_ex_bold";
  src: url("../assets/fonts/Proxima_Nova_Extrabold.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Corm_semi_bold_italic";
  src: url("../assets/fonts/Cormorant-SemiBoldItalic.otf");
  src: format("opentype");
} */

@font-face {
  font-family: "Town_80_Bold_Italic";
  src: url("../assets/fonts/Town80Text-BoldItalic.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Town_80_Bold";
  src: url("../assets/fonts/Town80Text-Bold.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Town_80_Book";
  src: url("../assets/fonts/Town80Text-Book.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Town_80_Light";
  src: url("../assets/fonts/Town80Text-Light.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Town_80_Medium";
  src: url("../assets/fonts/Town80Text-Medium.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Town_80_Medium_Italic";
  src: url("../assets/fonts/Town80Text-MediumItalic.otf");
  src: format("opentype");
}

.town80book {
  font-family: "Town_80_Book";
}

.login_button {
  font-family: "Town_80_Bold";
  border-radius: 15px;
}

.account:focus {
  outline: none;
}

.info {
  font-family: "Town_80_Book";
}

.account_buttons {
  font-family: "Town_80_Book";
}

.changePass {
  font-family: "Town_80_Book";
}

.navbar_item:hover {
  color: red;
}

.footer {
  /* flex: 1; */
  background-color: #2d506c;
  opacity: 1;
  max-height: 100px;
  width: 100%;
  color: white;
  padding: 10px 40px 10px 140px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.account_toggle {
  position: absolute;
  right: 76px;
  top: 150px;
  background-color: "#6d1835";
  z-index: 2;
  min-width: 320px;
  width: 25vw;
  /* padding-bottom: 10px; */
}
@media screen and (max-width: 992px) {
  .footer {
    padding-left: 10px;
  }
}
@media screen and (max-width: 545px) {
  .account_toggle {
    top: 220px;
    left: 30px;
  }
}

@font-face {
  font-family: "Town_80_Book";
  src: url("./assets/fonts/Town80Text-Book.otf");
  src: format("opentype");
}

body {
  margin: 0;
  font-family: "Town_80_Book", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Town_80_Book", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
